<template>
    <div class="index">
        <!-- 个人中心 -->
        <div class="regionTopImg" :style="{ backgroundImage: `url(${bannerUrl})` }">
            <!-- <img :src="bannerUrl" alt=""> -->
        </div>
        <div class="region">
            <div class="item" v-for="item in dataList" :key="item.id">
                <div class="left">
                    <p class="name">{{ item.consultName }}</p>
                    <p>{{ item.date }} {{ item.week }}
                        <span>{{ item.startTime }}-{{ item.endTime }}</span>
                    </p>
                    <!-- <p>{{ item.time }}</p> -->
                </div>
                <div class="right" v-if="item.status === 1" @click="cancel(item.id)">
                    <span>取消</span>
                </div>
                <div class="right" @click="toPay(item)"  v-else-if="item.status === 4">
                    <span>去支付</span>
                    <!-- <span>不可取消</span> -->
                </div>
                <div class="right not" v-else>
                    <!-- <span>不可取消</span> -->
                </div>
                <div class="img">
                    <img v-if="item.status === 1" src="../../assets/home/yyy.png" alt="">
                    <img v-if="item.status === 2" src="../../assets/home/jxz.png" alt="" />
                    <img v-if="item.status === 3" src="../../assets/home/yjs.png" alt="" />
                    <img v-if="item.status === 4" src="../../assets/home/wzf.png" alt="" />
                    <img v-if="item.status === 5" src="../../assets/home/yqx.png" alt="" />

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ConsultData, cancelRecord } from '@/api/record'
import { getSetting } from '@/api/setting'
export default {
    methods: {
        choice(val) {
            this.$router.push(`/home/${val}`)
        }
    },
    data() {
        return {
            bannerUrl: '',
            dataList: [],
            settingInfo: {}//配置信息
        }
    },
    methods: {
        // 获取预约列表
        async getList(userId) {
            const res = await ConsultData(userId);
            console.log(res);
            this.dataList = res.data;
            this.dataList.forEach(item => {
                if (item.week === 1) {
                    item.week = '周一';
                } else if (item.week === 2) {
                    item.week = '周二';
                } else if (item.week === 3) {
                    item.week = '周三';
                } else if (item.week === 4) {
                    item.week = '周四';
                } else if (item.week === 5) {
                    item.week = '周五';
                } else if (item.week === 6) {
                    item.week = '周六';
                } else if (item.week === 7) {
                    item.week = '周日';
                }
            })
        },
        // 取消预约
        async cancel(val) {

            this.$confirm('确认取消该预约?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const res = await cancelRecord(val);
                console.log(res);
                if (res.code === 200) {
                    if(this.settingInfo.reserveIsPayment === true){
                        this.$message({
                        showClose: true,
                        message: '取消成功！退款可能存在延迟请耐心等待。',
                        type: 'success'
                    });
                    } else {
                        this.$message({
                        showClose: true,
                        message: '取消成功！',
                        type: 'success'
                    });
                    }
                    
                } else {
                    this.$message({
                        showClose: true,
                        message: '取消失败！' + res.msg,
                        type: 'error'
                    });
                }
                this.getList(JSON.parse(localStorage.getItem('userInfo')).id);
            }).catch(() => {
                //   this.$message({
                //     type: 'info',
                //     message: '已取消删除'
                //   });          
            });

        },
        // 去支付
        toPay(val) {
            console.log(val);
            // 存储此订单需要的信息
            let consultInfo = {
                consultId: val.consultId,
                userId: JSON.parse(localStorage.getItem('userInfo')).id,
                date: val.date,
                consultDateId: val.consultDateId

            }
            // console.log(consultInfo);
            sessionStorage.setItem('consultInfo', JSON.stringify(consultInfo));
            sessionStorage.setItem('paymentInfo', JSON.stringify(val.paymentInfo));
            this.$router.push({
                path: '/home/appointmentDetails',
                query: {
                    type:val.paymentType
                }
            })
        },
        // 获取配置信息
        async getLogs() {
            const res = await getSetting();
            // console.log(res);
            if (res.code === 200) {
                this.settingInfo = res.data;
            }
        },
    },
    created() {
        this.bannerUrl = sessionStorage.getItem('banner');
        let userId = JSON.parse(localStorage.getItem('userInfo')).id;
        this.getList(userId);
        this.getLogs();
    }
}
</script>

<style lang="scss" scoped>
.index {

    // box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
    // height: 100%;
    .regionTopImg {
        img {
            height: 100%;
        }
    }

    .region {
        background: white;
        padding-top: 20px;

        // height: 100%;
        // height: 100%;

        .item {
            margin: auto;
            position: relative;
            // box-shadow: 0px 2px 4px 0px rgba(148, 146, 146, 0.25);
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1700);
            // height: 130px;
            width: 650px;
            margin-bottom: 20px;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            // justify-content: center;
            padding: 30px 15px 25px;
            align-items: center;
            box-sizing: border-box;


            .left {
                p {
                    margin: 0;
                    font-size: 16px;
                    color: var(--text-color);
                    margin-top: 10px;

                    &:nth-child(1) {
                        color: var(--tittle-color);
                        margin-top: 0px;
                        font-size: 18px;
                    }

                    span {
                        font-size: 16px;
                    }
                }


            }

            .right {
                span {
                    display: inline-block;
                    width: 90px;
                    height: 35px;
                    line-height: 35px;
                    background-color: var(--custom-color);
                    color: white;
                    text-align: center;
                    font-size: 14px;
                    border-radius: 6px;
                    cursor: pointer;
                }
            }

            .right.not {
                span {
                    display: inline-block;
                    width: 90px;
                    height: 35px;
                    line-height: 35px;
                    background-color: #ccc;
                    color: white;
                    text-align: center;
                    font-size: 14px;
                    border-radius: 6px;
                    cursor: pointer;
                }
            }

            .img {
                position: absolute;
                right: 0;
                top: 0;

                img {
                    width: 56px;
                }
            }

        }
    }
}

@media screen and (min-width: 751PX) {
    .index {
        .top {
            height: 80px;
        }

        // background-image: url('../../assets/home/backgroundImage.png');
        // background-repeat: no-repeat;
        // background-position: center;
        // background-attachment: fixed;
        background-size: cover;
        // display: flex;
        // justify-content: center;
    }

    .item {

        // height: 110px;
        span {
            font-size: 20px;
        }
    }

    .regionTopImg {
        height: 200px;

        width: 100%;
        // background-image: url('../../assets/home/banner.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }

}

@media screen and (max-width: 750PX) {
    .regionTopImg {
        height: 150px;
        width: 100%;
        // background-image: url('../../assets/home/banner.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .index {
        width: 100%;



        .region {
            background: white;

            .item {
                width: 80%;

                p {
                    span {
                        display: block;
                    }
                }
            }
        }
    }
}
</style>